import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

interface UseKanaRecaptchaProps {
  capchaToken: string;
  setCapchaToken: Dispatch<SetStateAction<string>>;
}

interface UseKanaRecaptchaReturnData {
  recaptchaRef: MutableRefObject<ReCAPTCHA | null>;
  handleRecaptcha: (token: string | null) => void;
}

export const useKanaRecaptcha = ({
  capchaToken,
  setCapchaToken,
}: UseKanaRecaptchaProps): UseKanaRecaptchaReturnData => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const handleRecaptcha = useCallback((token: string | null) => {
    setCapchaToken(token || "");
  }, []);

  useEffect(() => {
    const refreshCaptcha = (): void => {
      if (recaptchaRef.current && capchaToken) {
        recaptchaRef.current.reset();
        setCapchaToken("");
      }
    };

    // eslint-disable-next-line no-undef
    let tokenRefreshTimeout: NodeJS.Timeout | null = null;

    if (capchaToken) {
      tokenRefreshTimeout = setTimeout(refreshCaptcha, 110000); // 110 seconds
    }

    return () => {
      if (tokenRefreshTimeout) {
        clearTimeout(tokenRefreshTimeout);
      }
    };
  }, [capchaToken]);

  return { recaptchaRef, handleRecaptcha };
};
