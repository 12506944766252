import { MutableRefObject, RefObject, useLayoutEffect, useRef, useState } from "react";

import { ComponentDynamicPosition } from "../../../../../models";

interface UseRepeaterRowContentReturnData {
  repeaterRef: RefObject<HTMLDivElement>;
  deleteButtonPosition: ComponentDynamicPosition;
}

export const useRepeaterRowContent = (): UseRepeaterRowContentReturnData => {
  const repeaterRef = useRef<HTMLDivElement>(null);

  const [deleteButtonPosition, setDeleteButtonPosition] = useState({ top: 0, left: 0 });

  const updateDeleteButtonPosition = (): void => {
    const ref = repeaterRef as MutableRefObject<HTMLDivElement | null>;

    setDeleteButtonPosition({
      left: (ref.current?.offsetLeft || 0) + (ref.current?.offsetWidth || 0) / 2,
      top: ref.current?.offsetTop || 0,
    });
  };

  useLayoutEffect(() => {
    updateDeleteButtonPosition();

    const handleResize = (): void => updateDeleteButtonPosition();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [repeaterRef]);

  return { repeaterRef, deleteButtonPosition };
};
