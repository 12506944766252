import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../constants";
import {
  Metric,
  PublicBadgeDetailsParams,
  PublicCodeDetailsParams,
  PublicDeveloperDetailsParams,
  PublicVerifierDetailsParams,
} from "../../../../models";
import { register } from "../../../../service/identity";
import { contactOrganisation } from "../../../../service/public";
import { ServiceError, Status } from "../../../../service/Shared";
import { saveEvent } from "../../../../service/stats";
import { useIsLoadingWrapper } from "../../../../utils";
import { Toast } from "../../../../widget";

interface UseContactUsModalFormProps {
  organisationType: OrganisationTypeConstants;
  closeModal: () => void;
}

interface UseContactUsModalReturnData {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
  password: string;
  errors: ServiceError[] | undefined;
  displaySecondStepSuccessBox: boolean;
  firstStepComplete: boolean;
  secondStepComplete: boolean;
  isHandleFirstStepSubmitLoading: boolean;
  recaptchaToken: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  setLastName: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setMessage: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  handleFirstStepSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleSecondStepSubmit: (e: FormEvent<HTMLFormElement>) => void;
  setRecaptchaToken: Dispatch<SetStateAction<string>>;
}

export const useContactUsModalForm = ({
  organisationType,
  closeModal,
}: UseContactUsModalFormProps): UseContactUsModalReturnData => {
  const { developerUuid } = useParams<PublicDeveloperDetailsParams>();
  const { standardUuid } = useParams<PublicCodeDetailsParams>();
  const { verifierUuid } = useParams<PublicVerifierDetailsParams>();
  const { badgeUuid } = useParams<PublicBadgeDetailsParams>();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>([]);

  const [displaySecondStepSuccessBox, setDisplaySecondStepSuccessBox] = useState(true);
  const [firstStepComplete, setFirstStepComplete] = useState(false);
  const [secondStepComplete, setSecondStepComplete] = useState(false);
  const [isHandleFirstStepSubmitLoading, setIsHandleFirstStepSubmitLoading] = useState(false);

  const [recaptchaToken, setRecaptchaToken] = useState("");

  const objectUuid = developerUuid || standardUuid || verifierUuid || badgeUuid;

  const handleFirstStepSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    if (!firstName || !lastName || !email) {
      const errorList = [];

      if (!firstName) {
        errorList.push({
          code: "NO_FIRSTNAME",
          message: "First name must be completed",
        });
      }

      if (!lastName) {
        errorList.push({
          code: "NO_FIRSTNAME",
          message: "Last name must be completed",
        });
      }

      if (!email) {
        errorList.push({
          code: "NO_EMAIL",
          message: "Email address must be completed",
        });
      }

      setErrors(errorList);
      return;
    }
    if (objectUuid) {
      const res = await contactOrganisation({
        objectUuid,
        objectType: organisationType as string,
        firstName,
        lastName,
        email,
        message,
        clientCaptchaResponseToken: recaptchaToken,
      });

      if (res.status === Status.Success) {
        Toast.success({ message: "Thanks for getting in touch." });
        closeModal();
        setFirstStepComplete(true);
        saveEvent({
          metric: Metric.Enquiry,
          objectType: organisationType as string,
          objectUuid,
          timestamp: null,
        });
      }

      if (res.status === Status.Error) {
        Toast.error({ message: "There was an error sending your message." });
        setErrors(res.errors);
      }
    }
  }, setIsHandleFirstStepSubmitLoading);

  const handleSecondStepSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    const res = await register({
      firstName,
      lastName,
      email,
      password,
    });

    if (res.status === Status.Success) {
      setSecondStepComplete(true);
    } else {
      setErrors(res.errors);
      setDisplaySecondStepSuccessBox(false);
    }
  };

  useEffect(() => {
    document.getElementById("contactUsSecondStep")?.scrollIntoView({ behavior: "smooth" });

    document.getElementById("contactUsThirdStep")?.scrollIntoView({ behavior: "smooth" });
  }, [firstStepComplete, secondStepComplete]);

  return {
    firstName,
    lastName,
    email,
    message,
    password,
    errors,
    displaySecondStepSuccessBox,
    firstStepComplete,
    secondStepComplete,
    isHandleFirstStepSubmitLoading,
    recaptchaToken,
    setFirstName,
    setLastName,
    setEmail,
    setMessage,
    setPassword,
    handleFirstStepSubmit,
    handleSecondStepSubmit,
    setRecaptchaToken,
  };
};
