import { Dispatch, SetStateAction } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { useKanaRecaptcha } from "./useKanaRecaptcha";

interface KanaRecaptchaProps {
  capchaToken: string;
  setCapchaToken: Dispatch<SetStateAction<string>>;
}

export const KanaRecaptcha = ({ capchaToken, setCapchaToken }: KanaRecaptchaProps): JSX.Element => {
  const { recaptchaRef, handleRecaptcha } = useKanaRecaptcha({ capchaToken, setCapchaToken });

  return window.RECAPTCHA_SITE_KEY ? (
    <ReCAPTCHA ref={recaptchaRef} sitekey={window.RECAPTCHA_SITE_KEY} onChange={handleRecaptcha} />
  ) : (
    <p className="NoData">Unable to render reCAPTCHA</p>
  );
};
