// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint @typescript-eslint/no-unused-vars: "off" */
/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: "off" */
// PublicQueryService
import { DocumentNode, gql } from "@apollo/client";

import { OrganisationType } from "../../models";
import { generateGraphQLHelpers, jsonReviver, SearchResultsGraphQL } from "../../utils";
import { apolloPublic } from "../apolloPublic";
import { BaseResponseWithMultiple, BaseResponseWithSingle, FilterGroups, ServiceError, Status } from "../Shared";
import {
  GetBadgeDetailsRequest,
  GetBadgeDetailsResponse,
  GetDeveloperDetailsRequest,
  GetDeveloperDetailsResponse,
  GetGroupDetailsRequest,
  GetInvitationDetailsRequest,
  GetInvitationDetailsResponse,
  GetProjectAggregateRequest,
  GetProjectAggregateResponse,
  GetProjectDetailsRequest,
  GetProjectDocumentsRequest,
  GetProjectDocumentsResponse,
  GetProjectIssuancesRequest,
  GetProjectIssuancesResponse,
  GetProjectTypeDetailsRequest,
  GetProjectTypeDetailsResponse,
  GetStandardDetailsRequest,
  GetStandardDetailsResponse,
  GetStandardSpecificSchemaDetailsRequest,
  GetStandardSpecificSchemaDetailsResponse,
  GetVerifierDetailsRequest,
  GetVerifierDetailsResponse,
  SearchProjectsRequest,
  SearchProjectsResponse,
} from "./Types";

export const searchProjects = async (
  req: SearchProjectsRequest & FilterGroups<SearchProjectsRequest["filter"]>
): Promise<BaseResponseWithSingle<SearchProjectsResponse>> => {
  const graphqlTypes = {
    "developer.displayName": "String",
    "developer.uuid": "UUID",
    "badges.displayName": "String!",
    "badges.uuid": "UUID!",
    "certifications.displayName": "String!",
    "certifications.uuid": "UUID!",
    "projectType.displayName": "String!",
    "projectType.uuid": "UUID!",
    "standard.displayName": "String!",
    "standard.uuid": "[UUID]",
    calculatedIssuancePriceMin: "Decimal!",
    calculatedIssuancePriceMax: "Decimal!",
    calculatedTotalQuantityMin: "Decimal!",
    calculatedTotalQuantityMax: "Decimal!",
    calculatedVintageEndDateMin: "Date",
    calculatedVintageEndDateMax: "Date",
    addressCountryCode: "String!",
    addressRegion: "String!",
    status: "String!",
    cachedPiuQuantity: "Decimal!",
    cachedVcuQuantity: "Decimal!",
    displayName: "String!",
    name: "String!",
    uuid: "UUID!",
    "listing.availableForSale": "Boolean!",
    "listing.seekingFunding": "Boolean!",
    "listing.pilotProject": "Boolean!",
    tags: "String!",
    "group.groupUuid": "UUID!",
  };

  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  variables.badgeUuid = req.badgeUuid;
  variables.certificationUuid = req.certificationUuid;
  const QUERY = `
    query ($badgeUuid: UUID, $certificationUuid: UUID, ${variablesBlock}){
      publicSearchProjects (
        request: {badgeUuid: $badgeUuid, certificationUuid: $certificationUuid}
        ${queryBlock}
      ){
        aspects {
          calculatedIssuancePriceMax
          calculatedIssuancePriceMin
          calculatedTotalQuantityMax
          calculatedTotalQuantityMin
          calculatedVintageEndDateMax
          calculatedVintageEndDateMin
          addressCountryCodes
          regions{
            addressCountryCode
            addressRegions
          }
          statuses
          developers {
            displayName
            uuid
          }
          standards {
            displayName
            uuid
          }
          tags {
            name
          }
          badges {
            uuid
            displayName
          }
          certifications {
            uuid
            displayName
          }
          projectTypes {
            uuid
            displayName
          }
        }
        edges {
          cursor
          node {
            uuid
            name
            displayName
            status
            addressCountryCode
            cachedPiuQuantity
            cachedVcuQuantity
            developer {
              displayName
              files {
                type
                file {
                  url
                }
              }
            }
            standard {
              displayName
              uuid
            }
            locationCoordinates
            calculatedIssuancePriceMax
            calculatedIssuancePriceMin
            calculatedTotalQuantityMax
            calculatedTotalQuantityMin
            calculatedVintageEndDateMax
            calculatedVintageEndDateMin
            listing {
              availableForSale
              showPriceInDirectory
              content
              hidden
              seekingFunding
              pilotProject
            }
            listingFiles {
              uuid,
              file {
                filename
                url
                uuid
              }
            }
            badges {
              uuid
              displayName
              files {
                file {
                  url
                }
                type
              }
              type
            }
            certifications {
              uuid
              displayName
              files {
                file {
                  url
                }
                type
              }
              type
            }
            projectType {
              uuid
              displayName
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
      }
    }
  `;
  return apolloPublic
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.publicSearchProjects as SearchResultsGraphQL<
        SearchProjectsResponse["results"][number]
      >;
      const aspects = response.data.publicSearchProjects.aspects as SearchProjectsResponse["aspects"];

      const results = data.edges.map((e) => {
        if (e.node?.listing?.content) e.node.listing.content = JSON.parse(e.node.listing.content, jsonReviver);
        return e.node;
      });
      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
          aspects,
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetProjectDetailsResponseGraphQL {
  projectDetails: any[];
}

export const getProjectDetails = async (req: GetProjectDetailsRequest): Promise<BaseResponseWithSingle<any>> => {
  const QUERY = gql`
    query ($projectUuid: UUID!) {
      projectDetails(projectUuid: $projectUuid) {
        displayName
        name
        uuid
        addressCity
        addressCountry
        addressCountryCode
        addressRegion
        addressLine1
        dataScore
        areaNetHa
        status
        standardSpecificData
        cachedVcuQuantity
        cachedPiuQuantity
        calculatedContactable
        locationCoordinates
        locationGridReference
        developer {
          displayName
          uuid
          files {
            file {
              url
            }
            type
          }
          listing {
            content
          }
        }
        standard {
          displayName
          uuid
          files {
            file {
              url
            }
          }
        }
        validator {
          displayName
          uuid
          files {
            file {
              url
            }
          }
        }
        listing {
          availableForSale
          contactAlternativeEmail
          contactOptOut
          content
          hidden
          rowVersion
          showPriceInDirectory
          seekingFunding
          pilotProject
        }
        listingFiles {
          uuid
          file {
            filename
            url
            mimeType
          }
        }
        badges {
          uuid
          displayName
          files {
            file {
              url
            }
            type
          }
          type
        }
        projectType {
          displayName
          uuid
        }
        group {
          groupName
          groupUuid
        }
        projectReference {
          externalReference
        }
        standardSpecificSchemaUuid
      }
    }
  `;
  return apolloPublic
    .query<GetProjectDetailsResponseGraphQL>({
      query: QUERY,
      variables: { projectUuid: req.projectUuid },
    })
    .then((response) => {
      if (
        response.data.projectDetails[0].standardSpecificData &&
        typeof response.data.projectDetails[0].standardSpecificData === "string"
      )
        response.data.projectDetails[0].standardSpecificData = JSON.parse(
          response.data.projectDetails[0].standardSpecificData,
          jsonReviver
        );
      if (
        response.data.projectDetails[0].listing?.content &&
        typeof response.data.projectDetails[0].listing?.content === "string"
      )
        response.data.projectDetails[0].listing.content = JSON.parse(
          response.data.projectDetails[0].listing.content,
          jsonReviver
        );
      if (
        response.data.projectDetails[0].developer?.listing?.content &&
        typeof response.data.projectDetails[0].developer?.listing?.content === "string"
      ) {
        response.data.projectDetails[0].developer.listing.content = JSON.parse(
          response.data.projectDetails[0].developer?.listing?.content,
          jsonReviver
        );
      }

      // TODO KAN-4532 remove this after BE FIX
      if (response.data.projectDetails[0].addressRegion) {
        response.data.projectDetails[0].addressRegion = response.data.projectDetails[0].addressRegion.replaceAll(
          "�",
          "ô"
        );
      }
      return {
        status: Status.Success,
        data: response.data.projectDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetInvitationDetailsResponseGraphQL {
  invitationDetails: GetInvitationDetailsResponse[];
}

export const getInvitationDetails = async (
  req: GetInvitationDetailsRequest
): Promise<BaseResponseWithSingle<GetInvitationDetailsResponse>> => {
  const QUERY = gql`
    query ($token: UUID!) {
      invitationDetails(request: { token: $token }) {
        targetEmail
        targetFirstName
        targetLastName
        targetOrganisation {
          displayName
        }
      }
    }
  `;
  return apolloPublic
    .query<GetInvitationDetailsResponseGraphQL>({
      query: QUERY,
      variables: { token: req.token },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.invitationDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetProjectIssuancesResponseGraphQL {
  projectIssuances: GetProjectIssuancesResponse[];
}

export const getProjectIssuances = async (
  req: GetProjectIssuancesRequest
): Promise<BaseResponseWithMultiple<GetProjectIssuancesResponse>> => {
  const QUERY = gql`
    query ($projectUuid: UUID!) {
      projectIssuances(projectUuid: $projectUuid) {
        uuid
        price
        quantity
        quantityType
        vintageStartDate
        vintageEndDate
        verifier {
          displayName
        }
      }
    }
  `;
  return apolloPublic
    .query<GetProjectIssuancesResponseGraphQL>({
      query: QUERY,
      variables: { projectUuid: req.projectUuid },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.projectIssuances,
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

const getOrganisationDetailsHelper = (type: OrganisationType): DocumentNode => {
  let queryType = "";
  switch (type) {
    case OrganisationType.Developer:
      queryType = `query ($developerUuid: UUID!) {
        developerDetails(developerUuid: $developerUuid) {`;
      break;
    case OrganisationType.Standard:
      queryType = `query ($standardUuid: UUID!) {
          standardDetails(standardUuid: $standardUuid) {`;
      break;
    case OrganisationType.Verifier:
      queryType = `query ($verifierUuid: UUID!) {
            verifierDetails(verifierUuid: $verifierUuid) {`;
      break;
    case OrganisationType.Badge:
      queryType = `query ($badgeUuid: UUID!) {
            badgeDetails(badgeUuid: $badgeUuid) {`;
      break;
    default:
      break;
  }
  return gql`
    ${queryType}
        uuid
        websiteUrl
        rowVersion
        contactEmail
        contactPhone
        calculatedContactable
        displayName
        socialMediaFacebookUrl
        socialMediaInstagramUsername
        socialMediaLinkedInUrl
        socialMediaTwitterUsername
        socialMediaYoutubeChannel
        files {
          type
          file {
            url
          }
        }
        listing {
          content
          rowVersion
        }
        listingFiles {
          uuid
          file {
            url
          }
        }
        ${type === OrganisationType.Standard ? "projectTypes { uuid, displayName }" : ""}
        ${type === OrganisationType.Badge ? "type" : ""}
      }
    }
  `;
};

interface GetDeveloperDetailsResponseGraphQL {
  developerDetails: GetDeveloperDetailsResponse[];
}

export const getDeveloperDetails = async (
  req: GetDeveloperDetailsRequest
): Promise<BaseResponseWithSingle<GetDeveloperDetailsResponse>> => {
  const QUERY = getOrganisationDetailsHelper(OrganisationType.Developer);

  return apolloPublic
    .query<GetDeveloperDetailsResponseGraphQL>({
      query: QUERY,
      variables: { developerUuid: req.developerUuid },
    })
    .then((response) => {
      if (response.data.developerDetails[0].listing?.content)
        response.data.developerDetails[0].listing.content = JSON.parse(
          response.data.developerDetails[0].listing.content,
          jsonReviver
        );
      return {
        status: Status.Success,
        data: response.data.developerDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetStandardDetailsResponseGraphQL {
  standardDetails: GetStandardDetailsResponse[];
}

export const getStandardDetails = async (
  req: GetStandardDetailsRequest
): Promise<BaseResponseWithSingle<GetStandardDetailsResponse>> => {
  const QUERY = getOrganisationDetailsHelper(OrganisationType.Standard);

  return apolloPublic
    .query<GetStandardDetailsResponseGraphQL>({
      query: QUERY,
      variables: { standardUuid: req.standardUuid },
    })
    .then((response) => {
      if (response.data.standardDetails[0].listing?.content)
        response.data.standardDetails[0].listing.content = JSON.parse(
          response.data.standardDetails[0].listing.content,
          jsonReviver
        );
      return {
        status: Status.Success,
        data: response.data.standardDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetVerifierDetailsResponseGraphQL {
  verifierDetails: GetVerifierDetailsResponse[];
}

export const getVerifierDetails = async (
  req: GetVerifierDetailsRequest
): Promise<BaseResponseWithSingle<GetVerifierDetailsResponse>> => {
  const QUERY = getOrganisationDetailsHelper(OrganisationType.Verifier);

  return apolloPublic
    .query<GetVerifierDetailsResponseGraphQL>({
      query: QUERY,
      variables: { verifierUuid: req.verifierUuid },
    })
    .then((response) => {
      if (response.data.verifierDetails[0].listing?.content)
        response.data.verifierDetails[0].listing.content = JSON.parse(
          response.data.verifierDetails[0].listing.content,
          jsonReviver
        );
      return {
        status: Status.Success,
        data: response.data.verifierDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetBadgeDetailsResponseGraphQL {
  badgeDetails: GetBadgeDetailsResponse[];
}

export const getBadgeDetails = async (
  req: GetBadgeDetailsRequest
): Promise<BaseResponseWithSingle<GetBadgeDetailsResponse>> => {
  const QUERY = getOrganisationDetailsHelper(OrganisationType.Badge);

  return apolloPublic
    .query<GetBadgeDetailsResponseGraphQL>({
      query: QUERY,
      variables: { badgeUuid: req.badgeUuid },
    })
    .then((response) => {
      if (response.data.badgeDetails[0].listing?.content)
        response.data.badgeDetails[0].listing.content = JSON.parse(
          response.data.badgeDetails[0].listing.content,
          jsonReviver
        );
      return {
        status: Status.Success,
        data: response.data.badgeDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetProjectDocumentsResponseGraphQL {
  projectDocuments: GetProjectDocumentsResponse[];
}

export const getProjectDocuments = async (
  req: GetProjectDocumentsRequest
): Promise<BaseResponseWithMultiple<GetProjectDocumentsResponse>> => {
  const QUERY = gql`
    query ($projectUuid: UUID!, $groupUuid: UUID) {
      projectDocuments(projectUuid: $projectUuid, groupUuid: $groupUuid) {
        uuid
        versionUuid
        type
        variant
        customVariant
        visibility
        createdAt
        file {
          filename
          url
          mimeType
          sizeBytes
          uuid
        }
      }
    }
  `;
  return apolloPublic
    .query<GetProjectDocumentsResponseGraphQL>({
      query: QUERY,
      variables: { projectUuid: req.projectUuid, groupUuid: req.groupUuid },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.projectDocuments,
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetProjectAggregateResponseGraphQL {
  projectAggregate: GetProjectAggregateResponse[];
}

export const getProjectAggregate = async (
  req: GetProjectAggregateRequest
): Promise<BaseResponseWithMultiple<GetProjectAggregateResponse>> => {
  const QUERY = gql`
    query (
      $filterBy: String
      $filterOperator: String
      $filterValue: String
      $groupBy: String
      $aggregate: String
      $aggregation: String
    ) {
      projectAggregate(
        request: {
          filterBy: $filterBy
          filterOperator: $filterOperator
          filterValue: $filterValue
          groupBy: $groupBy
          aggregate: $aggregate
          aggregation: $aggregation
        }
      ) {
        key
        value
      }
    }
  `;
  return apolloPublic
    .query<GetProjectAggregateResponseGraphQL>({
      query: QUERY,
      variables: {
        filterBy: req.filterBy,
        filterOperator: req.filterOperator,
        filterValue: req.filterValue,
        groupBy: req.groupBy,
        aggregate: req.aggregate,
        aggregation: req.aggregation,
      },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.projectAggregate,
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetProjectTypeDetailsResponseGraphQL {
  projectTypeDetails: GetProjectTypeDetailsResponse[];
}

export const getProjectType = async (
  req: GetProjectTypeDetailsRequest
): Promise<BaseResponseWithSingle<GetProjectTypeDetailsResponse>> => {
  const QUERY = gql`
    query ($projectTypeUuid: UUID!) {
      projectTypeDetails(projectTypeUuid: $projectTypeUuid) {
        displayName
        category
        listing {
          content
        }
        listingFiles {
          file {
            url
          }
          uuid
        }
      }
    }
  `;

  return apolloPublic
    .query<GetProjectTypeDetailsResponseGraphQL>({
      query: QUERY,
      variables: { projectTypeUuid: req.projectTypeUuid },
    })
    .then((response) => {
      if (response.data.projectTypeDetails[0].listing?.content)
        response.data.projectTypeDetails[0].listing.content = JSON.parse(
          response.data.projectTypeDetails[0].listing.content,
          jsonReviver
        );
      return {
        status: Status.Success,
        data: response.data.projectTypeDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetGroupDetailsResponseGraphQL {
  groupDetails: any[];
}

export const getPublicGroupDetails = (req: GetGroupDetailsRequest): Promise<BaseResponseWithSingle<any>> => {
  const variables = { projectGroupUuid: req.projectGroupUuid };

  const QUERY = gql`
    query ($projectGroupUuid: UUID!) {
      groupDetails(projectGroupUuid: $projectGroupUuid) {
        externalReference
        groupName
        groupGuid
        groupDescription
        manuallyUpdated
        registryMatch
        projects {
          addressCity
          addressCountryCode
          addressLine1
          addressLine2
          addressLine3
          addressRegion
          addressPostcode
          areaNetHa
          cachedPiuQuantity
          cachedVcuQuantity
          calculatedContactable
          developer {
            displayName
            listing {
              content
            }
            uuid
          }
          displayName
          duration
          listing {
            cachedHidden
            availableForSale
            content
            seekingFunding
            pilotProject
            showPriceInDirectory
          }
          listingFiles {
            file {
              url
            }
            uuid
          }
          locationCoordinates
          locationGridReference
          name
          projectType {
            displayName
            uuid
          }
          standard {
            displayName
            uuid
          }
          standardSpecificData
          startDate
          uuid
          validator {
            displayName
            uuid
          }
        }
      }
    }
  `;

  return apolloPublic
    .query<GetGroupDetailsResponseGraphQL>({
      query: QUERY,
      variables,
    })
    .then((response) => {
      if (response.data.groupDetails[0].projects && Array.isArray(response.data.groupDetails[0].projects)) {
        const parsedProjectArr = response.data.groupDetails[0].projects.map((el: any) => {
          if (el.listing?.content && typeof el.listing?.content === "string") {
            // eslint-disable-next-line no-param-reassign
            el.listing.content = JSON.parse(el.listing.content, jsonReviver);
          }

          if (el.standardSpecificData && typeof el.standardSpecificData === "string") {
            // eslint-disable-next-line no-param-reassign
            el.standardSpecificData = JSON.parse(el.standardSpecificData, jsonReviver);
          }

          return el;
        });

        response.data.groupDetails[0].projects = parsedProjectArr;
      }

      return {
        status: Status.Success,
        data: response.data.groupDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};
interface GetStandardSpecificSchemaDetailsResponseGraphQL {
  standardSpecificSchemaDetails: GetStandardSpecificSchemaDetailsResponse[];
}

export const getStandardSpecificSchemaDetails = async (
  req: GetStandardSpecificSchemaDetailsRequest
): Promise<BaseResponseWithSingle<GetStandardSpecificSchemaDetailsResponse>> => {
  const QUERY = gql`
    query ($standardSpecificSchemaUuid: UUID!) {
      standardSpecificSchemaDetails(standardSpecificSchemaUuid: $standardSpecificSchemaUuid) {
        definition
        schema
        standardUuid
        uuid
        validFrom
        validTo
        versionNumber
      }
    }
  `;

  return apolloPublic
    .query<GetStandardSpecificSchemaDetailsResponseGraphQL>({
      query: QUERY,
      variables: {
        standardSpecificSchemaUuid: req.standardSpecificSchemaUuid,
      },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: {
          ...response.data.standardSpecificSchemaDetails[0],
          definition: JSON.parse(response.data.standardSpecificSchemaDetails[0].definition, jsonReviver),
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};
